<template>
  <div class="sub-navigation">
    <div class="image-div">
      <img class="section-banner" alt="Woodtrends" src="/images/hele-keuken.jpeg">
    </div>
    <div class="container">
      <div class="text-div">
        <slot name="text"></slot>
        <span class="underline"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubBanner',
}
</script>

<style lang="scss">

$gold: #D6AD60;
$coal: #122620;

.sub-navigation {
  position: relative;
  height: 100%;
  .image-div {
    position: relative;
    @media (max-width: 1100px) {
      height: 300px;
    }
    .section-banner {
      width: 100%;
      position: relative;
      height: 100%;
      max-height: 450px;
      object-fit: cover;
      min-height: 100%;
    }
    &:before {
      content: "";
      background: rgba(34, 34, 34, 0.4);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
    }
  }


  .container {
    bottom: 160px;
    z-index: 20;
    @media (max-width: 1100px) {
      bottom: 120px;
    }
    @media (max-width: 700px) {
      bottom: 150px;
    }
    h1, h2 {
      color: white;
      font-size: 75px;
      @media (max-width: 1200px) {
        font-size: 50px;
      }
      @media (max-width: 800px) {
        font-size: 40px;
      }
    }
    .underline {
      position: relative;
      display: block;
      margin-top: 25px;
      width: 120px;
      height: 4px;
      background: white;
    }
  }

}
</style>
