<template>
  <div class="contact-page">
    <sub-banner>
      <template v-slot:text>
        <h1 class="fw-bold">Contact</h1>
      </template>
    </sub-banner>
    <div class="container-xxl py-5" style="margin-top: -100px">
      <div class="container">
        <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s"
             style="max-width: 600px; visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
          <h1 class="display-5 mb-4 fw-bold">Kom in contact met Woodtrends</h1>
        </div>
        <div class="row g-5">
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s"
               style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
            <a href="https://maps.app.goo.gl/fSt4VCWbHAYhD8qy6" style="text-decoration: none" target="_blank">
              <div class="bg-light blokje d-flex align-items-center w-100 p-4 mb-4">
                <div class="d-flex flex-shrink-0 align-items-center justify-content-center bg-dark"
                     style="width: 55px; height: 55px;">
                  <i class="fa fa-map-marker-alt text-white"></i>
                </div>
                <div class="ms-4">
                  <p class="mb-2 fs-5" style="color: #212529">Adres</p>
                  <h3 class="mb-0 fs-4">Broekhovenstraat 10d, 8081 HC Elburg</h3>
                </div>
              </div>
            </a>
            <a href="tel:0651024945" style="text-decoration: none" target="_blank">
              <div class="bg-light blokje d-flex align-items-center w-100 p-4 mb-4">
                <div class="d-flex flex-shrink-0 align-items-center justify-content-center bg-dark"
                     style="width: 55px; height: 55px;">
                  <i class="fa fa-phone-alt text-white"></i>
                </div>
                <div class="ms-4">
                  <p class="mb-2 fs-5" style="color: #212529">Bel ons</p>
                  <h3 class="mb-0 fs-4">+31 6 51024945</h3>
                </div>
              </div>
            </a>
            <a href="mailto:info@wood-trends.nl" style="text-decoration: none" target="_blank">
              <div class="bg-light blokje d-flex align-items-center w-100 p-4 mb-0">
                <div class="d-flex flex-shrink-0 align-items-center justify-content-center bg-dark"
                     style="width: 55px; height: 55px;">
                  <i class="fa fa-envelope-open text-white"></i>
                </div>
                <div class="ms-4">
                  <p class="mb-2 fs-5" style="color: #212529">Mail ons</p>
                  <h3 class="mb-0 fs-4">info@wood-trends.nl</h3>
                </div>
              </div>
            </a>
          </div>
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s"
               style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;">
            <div class="contact-form wow fadeInUp" data-wow-delay="0.2s"
                 style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
              <h2 class="fs-4 fw-bold">Vragen of een offerte aanvragen?</h2>
              <p class="fs-5">Met maatwerk is alles mogelijk, dus het is niet de vraag of het waarmaken van jouw
                droominterieur mogelijk is, maar hoe snel je die droom wil verwezenlijken. Wacht daarom niet en neem
                vandaag nog contact met ons op!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextImage from '@/components/TextImage.vue'
import SubBanner from "@/components/SubBanner";
import ListImage from "@/components/ListImage";

export default {
  name: "Contact",
  components: {
    SubBanner,
    TextImage,
    ListImage
  },
  created() {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss" scoped>

$gold: #D6AD60;

.contact-title {
  @media (max-width: 700px) {
    font-size: 25px;
    line-height: 30px;
  }
}

.blokje {
  background-color: #ececec !important;
}

</style>
