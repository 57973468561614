import { createApp } from 'vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import VueMeta from 'vue-meta'

import 'aos/dist/aos.css'

createApp(App).use(router).mount('#app')
