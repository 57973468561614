<template>
  <div class="landing">
    <div class="introduction-image">
      <div class="centered-text position-absolute">
        <h1>Woodtrends</h1>
        <p>Vakmanschap op maat, elk detail een meesterwerk.</p>
      </div>
      <img src="/images/hele-keuken.jpeg" alt="Keuken Woodtrends">
    </div>
    <div class="container-xxl py-5">
      <div class="container">
        <div class="row g-5 align-items-center">
          <div class="col-lg-6">
            <h6 class="section-title text-start text-primary text-uppercase" style="font-weight: 800">Woodtrends</h6>
            <h1 class="mb-4" style="font-weight: 700">Meubels op maat gemaakt voor binnen en buiten</h1>
            <p class="mb-4" style="font-size: 18px">Of je nu op zoek bent naar unieke meubelstukken voor binnen of
              robuuste creaties voor buiten, wij zijn er om jouw wensen werkelijkheid te maken. Bij Woodtrends
              combineren we 30 jaar ervaring met puur vakmanschap om je droominterieur te realiseren.
              <br>
              <br>
              Onder leiding van Evert, onze vakman met gouden handen, en ondersteund door een toegewijde jongere met
              afstand tot de arbeidsmarkt, zorgen we dat elk meubelstuk jouw verwachtingen overtreft. Wij geloven niet
              in een 'one-size-fits-all' benadering. Elk stuk is op maat gemaakt, want jouw wens is onze standaard. Dat
              menen we.</p>
            <router-link to="/over-ons" class="btn btn-primary py-3 px-5 mt-2">Over ons</router-link>
          </div>
          <div class="col-lg-6">
            <div class="row g-3">
              <div class="col-6 text-end">
                <img class="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.1s" src="images/douche-tafel.jpeg"
                     style="margin-top: 25%; visibility: visible; animation-delay: 0.1s; animation-name: zoomIn;">
              </div>
              <div class="col-6 text-start">
                <img class="img-fluid rounded w-100 wow zoomIn" data-wow-delay="0.3s" src="images/kast2.jpeg"
                     style="visibility: visible; animation-delay: 0.3s; animation-name: zoomIn;">
              </div>
              <div class="col-6 text-end">
                <img class="img-fluid rounded w-50 wow zoomIn" data-wow-delay="0.5s" src="images/keuken.jpeg"
                     style="visibility: visible; animation-delay: 0.5s; animation-name: zoomIn;">
              </div>
              <div class="col-6 text-start">
                <img class="img-fluid rounded w-75 wow zoomIn" data-wow-delay="0.7s" src="images/hele-keuken.jpeg"
                     style="visibility: visible; animation-delay: 0.7s; animation-name: zoomIn;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="text-image" style="margin: 40px 0">
      <div class="container grid-container">
        <div class="img-side hero-image">
          <img src="/images/wandmeubel-verticaal.jpeg" alt="Woodtrends wandmeubel">
        </div>
        <div class="img-side hero-image">
          <img src="/images/kast-verticaal.jpeg" alt="Woodtrends kast">
        </div>
        <div class="img-side hero-image">
          <img src="/images/kast2-verticaal.jpeg" alt="Woodtrends">
        </div>
      </div>
    </section>
    <div class="container-xxl py-5">
      <div class="container">
        <div class="row g-5">
          <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s"
               style="visibility: visible; animation-delay: 0.1s; animation-name: fadeIn;">
            <div class="about-img">
              <img class="img-fluid" src="images/zwarte-kast.jpeg" alt="">
              <img class="img-fluid" src="images/houten-bank-buiten.jpg" alt="">
            </div>
          </div>
          <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s"
               style="visibility: visible; animation-delay: 0.5s; animation-name: fadeIn;">
            <h4 class="section-title">Waarom ons?</h4>
            <h1 class="display-5 mb-4 fw-bold">Waarom kies je voor Woodtrends</h1>
            <p class="fs-6"><strong>Kwalitatieve meubels op maat</strong><br>
              Bij Woodtrends ben je verzekerd van een buitengewone ervaring, waar kwaliteit en vakmanschap hand in hand
              gaan. Zo kiezen we alleen de beste, duurzame materialen die lang meegaan. Zijn bepaalde materialen er
              niet? Dan maken we dit helemaal zelf.</p>
            <br>
            <p class="fs-6"><strong>Werkplaats in Elburg</strong><br>
              Als vakmannen zorgen we ervoor dat de werkplaats een opgeruimde en goed verzorgde werkomgeving is, waar
              jouw meubels met precisie worden gemaakt. Je bent van harte welkom om een kijkje te nemen. We laten je
              graag zien waar en hoe wij te werk gaan.</p>
            <br>
            <p class="fs-6"><strong>Maatwerk</strong><br>
              Jouw wens is onze standaard. Daarom luisteren we goed wat jij graag zou willen, we denken met je mee en
              maken een uniek ontwerp op maat. Vervolgens gaan we voor je aan de slag zodat jij kan genieten van een
              huis of tuin dat helemaal aan jouw wensen voldoet.</p>
            <br>
            <p class="fs-6"><strong>Maatschappelijk betrokken</strong><br>
              We werken niet alleen met hout, maar ook met mensen. In onze werkplaats begeleid ik dan ook een jongen met
              een afstand tot de arbeidsmarkt, en daar ben ik trots op. We bieden hem een kans om zijn talenten te
              ontdekken (en die heeft hij 100%!) en zich verder te ontwikkelen. Daarnaast ondersteunen we graag
              maatschappelijke projecten. Zo hebben we bijvoorbeeld 11 voedselkastjes gebouwd, welke op straat geplaatst
              worden voor mensen die het financieel iets minder breed hebben. Zo proberen we ons steentje bij te dragen.
            </p>
            <br>
          </div>
        </div>
      </div>
    </div>
    <br>
  </div>
</template>

<style lang="scss" scoped>

$blue: #217287;
$blue-d2: #C2A484;
$coal: #E3D4C2;

.introduction-image {
  img {
    width: 100%;
    height: 450px;
    object-fit: cover;
    filter: brightness(0.5);
  }

  .centered-text {
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    text-align: center;
    width: 100%;
    padding: 0 20px;
    z-index: 9;

    h1 {
      font-size: 70px;
      @media (max-width: 600px) {
        font-size: 45px;
      }
      color: white;
      font-weight: 800;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    p {
      font-size: 24px;
      color: white;
      font-weight: 500;
    }
  }
}

.drie-items {
  .wow {
    .fact-item {
      background-color: #eaeaea !important;
      padding: 32px 8px !important;

      .fact-icon {
        margin-bottom: 16px;
      }
    }
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 20px;
  margin-top: 100px;
  @media (max-width: 1150px) {
    grid-template-columns: 1fr;
  }

  img {
    width: 100%
  }
}

.landing {
  .hero-banner-div {
    position: relative;
    @media (max-width: 600px) {
      height: 300px;
    }

    .hero-banner {
      width: 100%;
      margin-top: -100px;
      min-height: 100%;
      object-fit: cover;
      @media (max-width: 750px) {
        margin-top: 20px;
      }
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(34, 34, 34, 0.4);
    }
  }

  .hero-image {
    img {
      max-width: 600px;
      border-radius: 8px;
    }
  }

  .hero-navigator {
    background-color: white;
    width: 100%;
    margin-top: -200px;
    position: relative;
    padding: 45px 60px 60px;
    box-shadow: 0 4px 4px rgba(197, 197, 197, 0.25);

    @media (max-width: 1450px) {
      max-width: 90vw;
      margin-top: -170px;
    }

    @media (max-width: 1200px) {
      margin-top: -100px;
    }

    @media (max-width: 1000px) {
      max-width: 95vw;
      margin-top: -70px;
    }

    @media (max-width: 900px) {
      margin-top: 0;
    }

    @media (max-width: 750px) {
      padding: 25px;
      max-width: 90vw;
      margin-top: -60px;
    }


    .headline {
      h1 {
      }

      p {
        margin-bottom: 15px;
        color: #656565;

        @media (max-width: 750px) {
          margin-top: 20px;
          font-size: 20px;
        }

      }

      hr {
        border: none;
        background-color: $blue;
        width: 40px;
        height: 3px;
        margin: 0;
      }
    }

    .navigators {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      gap: 30px;
      height: 230px;
      position: relative;
      @media (max-width: 1450px) {
        height: 180px;
      }

      @media (max-width: 1200px) {
        height: 150px;
      }

      @media (max-width: 750px) {
        display: grid;
        height: unset;
        .different-navigator {
          height: 200px !important;
        }
      }


      .different-navigator {
        &::before {
          clip-path: polygon(88% 0, 100% 17%, 100% 100%, 0 100%, 0 0);
          background: #222222 !important;
        }

        &::after {
          clip-path: polygon(88% 0, 100% 17%, 100% 100%, 0 100%, 0 0);
        }
      }

      a {
        flex: 0 1 100%;
        cursor: default;
      }

      .single-navigator {
        width: 100%;
        height: 100%;
        max-width: 100%;
        position: relative;
        z-index: 4;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          background: linear-gradient(47.13deg, rgba(30, 30, 30, 0.8) 0%, rgba(207, 215, 255, 0) 97.4%);
          z-index: 1;
        }

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .zekerheden {
    background-color: $blue-d2;

    .container {
      padding-top: 70px;
      padding-bottom: 70px;

      b {
        color: $coal;
        font-size: 16px;
      }

      h3 {
        margin-top: 10px;
        font-weight: 800;
        font-size: 42px;
        color: white;
      }

      .items-grid {
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 60px 80px;
        @media (max-width: 950px) {
          grid-template-columns: 1fr;
        }

        .single-item {
          border-left: solid 2px $coal;
          min-height: 140px;
          height: auto;
          display: grid;
          align-items: stretch;
          padding-left: 15px;

          h5 {
            font-weight: 800;
            font-size: 22px;
            margin-top: 10px;
            color: white;
          }

          p {
            margin-top: -5px;
            color: white;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .social-media {
    margin: 50px 0 100px;

    .container {
      .social-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 950px) {
          align-items: flex-start;
          flex-direction: column;
          gap: 20px;
        }

        a {
          text-decoration: none;
          font-family: 'Avenir LT Std', sans-serif;
        }

        .right-side {
          height: auto;

          a {
            align-items: center;
            padding: 12px 50px;
            font-weight: 700;
            font-size: 20px;
            color: white;
            background-color: $blue;
            transition: .25s ease;

            &:hover {
              background-color: darken($blue, 15%);
            }
          }
        }

        .left-side {
          a {
            color: #474747;
            font-size: 22px;
            font-weight: 500;
          }

          h4 {
            font-weight: 900;
            font-size: 42px;
            color: #222222;
          }
        }
      }

      .social-content {
        display: grid;
        margin-top: 70px;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 1fr);
        @media (max-width: 950px) {
          display: block;
          height: unset;
          .div4, .div5, .div5, .div6, .div7, .div8 {
            display: none;
          }
        }
        height: 400px;
        gap: 35px;

        div {
          background: rgba(217, 217, 217, 0.6);
          overflow: hidden;
        }

        img {
          width: 100%;
          margin: auto;
          object-fit: cover;
          height: 100%;
        }
      }

      .div1 {
        grid-area: 1 / 1 / 2 / 2;
      }

      .div2 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .div3 {
        grid-area: 1 / 2 / 3 / 4;
      }

      .div4 {
        grid-area: 1 / 4 / 2 / 5;
      }

      .div5 {
        grid-area: 1 / 5 / 2 / 6;
      }

      .div6 {
        grid-area: 2 / 5 / 3 / 6;
      }

      .div7 {
        grid-area: 2 / 4 / 3 / 5;
      }
    }
  }

  .faq-section {
    .total-faq-headline {
      padding: 40px 0;
      background-color: $blue;
      margin-bottom: 50px;

      .container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .pill {
          width: fit-content;
          background-color: #DEEBFF;
          color: $blue;
          font-weight: 800;
          font-size: 16px;
          margin-bottom: 20px;
          cursor: default;
          border: none;
          padding: 10px 16px 8px;
          border-radius: 28px;
          font-family: 'Avenir LT Std', sans-serif;
        }

        h3, p {
          color: white;
        }

        h3 {
          font-size: 26px;
        }

        p {
          margin-top: 15px;
          font-family: 'Urbane', sans-serif;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .faq-content {
      .container {
        display: flex;
        flex-direction: column;
        max-width: 900px;

        .single-faq-item {
          cursor: pointer;
          overflow: hidden;
          border-top: solid 1px #ECEFF1;
          padding: 40px 0;

          .faq-headline {
            display: flex;
            justify-content: space-between;
            padding: 16px;

            h4 {
              font-size: 28px;
              font-weight: 600;
              @media (max-width: 700px) {
                font-size: 24px;
              }
            }

            svg {
              transition: .3s;
              transform: rotate(90deg);
              margin-right: 15px;
            }
          }

          .faq-content {
            font-size: 18px;
            font-weight: 300;
            padding: 0;
            height: 0;
            opacity: 0;
            transition: all .2s ease;
            overflow: hidden;
          }
        }

        .active-faq {
          .faq-headline {
            svg {
              transform: rotate(-90deg);
            }
          }

          .faq-content {
            height: auto;
            padding: 0 16px;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>

<script>

// @ is an alias to /src
import AOS from "aos";

import TextImage from '@/components/TextImage.vue'
import Voordelen from "@/components/Voordelen";

export default {
  name: 'HomeView',
  components: {
    Voordelen,
    TextImage
  },
  data() {
    return {
      showedFaq: false,
    }
  },
  created() {
    // window.scrollTo(0, 0)
  },
  methods: {
    toggleFaq(id) {
      console.log(id)
      let selectedFaq = document.getElementById('faq-' + id)

      if (selectedFaq.classList.contains('active-faq')) {
        selectedFaq.classList.remove('active-faq')
      } else {
        selectedFaq.classList.add('active-faq')
      }

      this.showedFaq = !this.showedFaq
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    AOS.init();
  }
}
</script>
