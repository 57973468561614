<template>
  <div class="portfolio">
    <sub-banner>
      <template v-slot:text>
        <h1 class="fw-bold">Foto's</h1>
      </template>
    </sub-banner>
    <div class="container-xxl py-5" style="margin-top: -110px">
      <div class="container">
        <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s"
             style="max-width: 500px; visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
          <p class="text-primary text-uppercase mb-2">Impressie</p>
          <h1 class="display-6 mb-0 fw-bold">Alles is mogelijk bij Woodtrends</h1>
        </div>
          <p class="text-center fs-5 mb-5">Met trots presenteren wij hier onze veelzijdige en op maat gemaakte projecten. Of het nu gaat om moderne
            woonkamers of sfeervolle buitenruimtes, wij brengen elke visie tot leven. Dankzij een combinatie van
            klantinzichten en ons vakmanschap creëren we ruimtes die zowel mooi als functioneel zijn. Neem een kijkje in
            ons portfolio om een greep uit onze gerealiseerde projecten te zien en laat je inspireren voor jouw
            toekomstige project!</p>
        <div class="row g-4">
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s"
               style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
            <div class="row g-3">
              <div class="col-12">
                <div class="project-item">
                  <img class="img-fluid" src="images/douche-tafel.jpeg" alt="">
                </div>
              </div>
              <div class="col-12">
                <div class="project-item">
                  <img class="img-fluid" src="images/kast2.jpeg" alt="">
                </div>
              </div>

            </div>
          </div>

          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s"
               style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;">
            <div class="row g-3">
              <div class="col-12">
                <div class="project-item">
                  <img class="img-fluid" src="images/wandmeubel.jpeg" alt="">
                </div>
              </div>
              <div class="col-12">
                <div class="project-item">
                  <img class="img-fluid" src="images/hele-keuken.jpeg" alt="">
                </div>
              </div>
              <div class="col-12">
                <div class="project-item">
                  <img class="img-fluid" src="images/keuken3-verticaal.jpeg" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s"
               style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
            <div class="row g-3">
              <div class="col-12">
                <div class="project-item">
                  <img class="img-fluid" src="images/kast.jpeg" alt="">
                </div>
              </div>
              <div class="col-12">
                <div class="project-item">
                  <img class="img-fluid" src="images/zwarte-kast.jpeg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row g-4 mt-3">
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s"
               style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInUp;">
            <div class="row g-3">
              <div class="col-12">
                <div class="project-item">
                  <img class="img-fluid" src="images/houten-bank-buiten.jpg" alt="">
                </div>
              </div>
              <div class="col-12">
                <div class="project-item">
                  <img class="img-fluid" src="images/hout-tv.jpg" alt="">
                </div>
              </div>

            </div>
          </div>

          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s"
               style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp; margin-top: 10px">
            <div class="row g-3">
              <div class="col-12">
                <div class="project-item">
                  <img class="img-fluid" src="images/houtwerk.jpg" alt="">
                </div>
              </div>
              <div class="col-12">
                <div class="project-item">
                  <img class="img-fluid" src="images/keuken-vert.jpg" alt="">
                </div>
              </div>
              <div class="col-12">
                <div class="project-item">
                  <img class="img-fluid" src="images/tv-kast.jpg" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s"
               style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInUp;">
            <div class="row g-3">
              <div class="col-12">
                <div class="project-item">
                  <img class="img-fluid" src="images/wit.jpg" alt="">
                </div>
              </div>
              <div class="col-12">
                <div class="project-item">
                  <img class="img-fluid" src="images/buiten.jpg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubBanner from "@/components/SubBanner";

export default {
  name: "Portfolio",
  components: {
    SubBanner,
  },
  created() {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss" scoped>

$gold: #D6AD60;
$gold-d2: #9a7836;
$coal: #122620;

.portfolio {

  .portfolio-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
    }
    gap: 45px;
    height: fit-content;
    padding-bottom: 100px;

    .portfolio-item {
      grid-column: span 2;
      display: flex;
      width: 100%;
      background: $gold;
      height: 100px;
      position: relative;
      max-height: 250px;
      min-height: 250px;
      @media (max-width: 900px) {
        min-height: 350px;
        max-height: 350px;
      }

      img {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(47.13deg, rgba(30, 30, 30, 0.55) 0%, rgba(207, 215, 255, 0) 100%);
        z-index: 1;
      }
    }

    .portfolio-item:nth-child(-n+2), .portfolio-item:nth-child(8n+10), .portfolio-item:nth-child(8n+9) {
      grid-column: span 3;
      max-height: 300px;
      min-height: 300px;
      @media (max-width: 900px) {
        min-height: 350px;
        max-height: 350px;
      }
    }
  }
}
</style>
